<template lang="pug">
  v-container(p-5 fluid)
    router-view
</template>

<script>
export default {
  name: 'TerminalsPage',
  data() {
    return {};
  },
};
</script>
